



































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';
import { Prop } from 'vue-property-decorator';
import Utils from '@/modules/Utils';

@Component({})
export default class CustomTimeClock extends Vue {

    @Global.State('lang') lang;

    @Prop() clock_time;
    @Prop() min_time;
    @Prop() max_time;
    @Prop() disabled;

    hours = '';
    minutes = '';
    time = '';    
    loading = true;
    is_dialog_open = false;
    close_on_content_click = false;
    min_time_in_minutes = 0;
    max_time_in_minutes = 0;
    check_ranges = false;

    mounted(){
        [this.hours, this.minutes] = this.clock_time.split(':');
        this.time = this.clock_time;
        if (this.min_time && this.max_time){
            this.min_time_in_minutes = Utils.convertTimeToMinutes(this.min_time);
            this.max_time_in_minutes = Utils.convertTimeToMinutes(this.max_time);
            this.check_ranges = true;
        }
        this.loading = false;
    }

    getNextHour(){
        const hours_number = +this.hours;
        const hours_string = hours_number === 23 
            ? '00'
            : hours_number >= 0 && hours_number < 9
            ? `0${hours_number + 1}`
            : `${hours_number + 1}`;

        const time_string = `${hours_string}:${this.minutes}`;
        const time_in_minutes = Utils.convertTimeToMinutes(time_string);
        this.hours = !this.check_ranges || (time_in_minutes >= this.min_time_in_minutes && time_in_minutes <= this.max_time_in_minutes)
            ? hours_string
            : this.hours;
    }

    getPreviousHour(){
        const hours_number = +this.hours;
        const hours_string = hours_number === 0
            ? '23'
            : hours_number > 0 && hours_number < 11
            ? `0${hours_number - 1}`
            : `${hours_number - 1}`;
        
        const time_string = `${hours_string}:${this.minutes}`;
        const time_in_minutes = Utils.convertTimeToMinutes(time_string);
        this.hours = !this.check_ranges || (time_in_minutes >= this.min_time_in_minutes && time_in_minutes <= this.max_time_in_minutes)
            ? hours_string
            : this.hours;
    }

    getNextMinute(){
        const minutes_number = +this.minutes;
        const minutes_string = minutes_number === 59
            ? '00'
            : minutes_number >= 0 && minutes_number < 9
            ? `0${minutes_number + 1}`
            : `${minutes_number + 1}`;

        const time_string = `${this.hours}:${minutes_string}`;
        const time_in_minutes = Utils.convertTimeToMinutes(time_string);
        this.minutes = !this.check_ranges || (time_in_minutes >= this.min_time_in_minutes && time_in_minutes <= this.max_time_in_minutes)
            ? minutes_string
            : this.minutes;
    }

    getPreviousMinute(){
        const minutes_number = +this.minutes;
        const minutes_string = minutes_number === 0
            ? '59'
            : minutes_number > 0 && minutes_number < 11
            ? `0${minutes_number - 1}`
            : `${minutes_number - 1}`;
        
        const time_string = `${this.hours}:${minutes_string}`;
        const time_in_minutes = Utils.convertTimeToMinutes(time_string);
        this.minutes = !this.check_ranges || (time_in_minutes >= this.min_time_in_minutes && time_in_minutes <= this.max_time_in_minutes)
            ? minutes_string
            : this.minutes;
    }

    updateClockTime(){
        this.time = `${this.hours}:${this.minutes}`;
        this.$emit('updateTime', this.time);
        this.is_dialog_open = false;
    }
}
